import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from './authConfig';

// Bootstrap components
import 'bootstrap/dist/css/bootstrap.min.css';

const pca = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={pca}>
        <App />
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById('root')
);