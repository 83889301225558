import { useEffect, useState } from 'react';
import './App.css';
import { useMsal, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { loginRequest } from './authConfig';
import { callMsGraph } from './graph';
import { ProfileData } from './components/ProfileData';
import { Button } from 'react-bootstrap';
import { PageLayout } from './components/PageLayout';
import { InteractionStatus } from '@azure/msal-browser';

/**
* Renders information about the signed-in user or a button to retrieve data about the user
*/
const ProfileContent = () => {
  const { instance, accounts, inProgress } = useMsal();
  const [graphData, setGraphData] = useState(null);

  function RequestProfileData() {
      // Silently acquires an access token which is then attached to a request for MS Graph data
      instance
          .acquireTokenSilent({
              ...loginRequest,
              account: accounts[0],
          })
          .then((response: any) => {
              callMsGraph(response.accessToken).then((response) => setGraphData(response));
          });
  }

  return (
    inProgress === InteractionStatus.None ? (
      <>
          <h5 className="card-title">Welcome {accounts[0].name}</h5>
          <br/>
          {graphData ? (
              <ProfileData graphData={graphData} />
          ) : (
              <Button variant="secondary" onClick={RequestProfileData}>
                  Request Profile Information
              </Button>
          )}
      </>) : (<></>)
  );
};

const WeatherForecastContent = () => {
    const { instance, accounts } = useMsal();
    const [weatherForecastData, setWeatherForecastData] = useState(null);

    function RequestWeatherForecastData() {
        instance
            .acquireTokenSilent({
                scopes: ["api://ea4f4c45-6fb3-4a05-8875-14c725750e75/All"],
                account: accounts[0],
            })
            .then(async (response: any) => {
                let forecastResponse = await fetch(`${process.env.REACT_APP_API_URL}/WeatherForecast`, {
                    method: "get",
                    headers: new Headers({
                        "Authorization": "Bearer " + response.accessToken,
                        "Content-Type": "application/json"
                    })
                    });
                    let forecastData = await forecastResponse.json();
                setWeatherForecastData(forecastData);
            });
    }

    function renderValuesTable(apiValues: any) {
        return (
            <table className='table table-striped' aria-labelledby="tableLabel">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Temperature (Celsius)</th>
                        <th>Summary</th>
                    </tr>
                </thead>
                <tbody>
                    {apiValues.map((apiValues: any) =>
                        <tr>
                            <td>{new Date(apiValues.date).toLocaleDateString()}</td>
                            <td>{apiValues.temperatureC}</td>
                            <td>{apiValues.summary}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        );
    }


    return (
        <>
            {weatherForecastData ? renderValuesTable(weatherForecastData) : (
              <Button variant="secondary" onClick={RequestWeatherForecastData}>
                  Request weather forecast
              </Button>
          )}

        </>
    );
};

/**
* If a user is authenticated the ProfileContent component above is rendered. Otherwise a message indicating a user is not authenticated is rendered.
*/
const MainContent = () => {
  return (
      <div className="App">
          <AuthenticatedTemplate>
            <>
              <ProfileContent />
              <WeatherForecastContent />
            </>
          </AuthenticatedTemplate>

          <UnauthenticatedTemplate>
              <h5>
                  <div>
                      Please sign-in.
                  </div>
              </h5>
          </UnauthenticatedTemplate>
      </div>
  );
};

export default function App() {
    useEffect(() => {
        const hostname = window.location.hostname;
        if (!hostname.startsWith('www.') && hostname !== 'localhost') {
          window.location.hostname = 'www.' + hostname;
        }
      }, []);

    return (
        <PageLayout>
            <div>
                <MainContent />
            </div>
        </PageLayout>
    );
}
